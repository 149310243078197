.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  border-radius: 6px;
  border: 1px solid var(--wpp-grey-color-300);
  background-color: var(--wpp-grey-color-100);
  width: 1390px;

  .wrapper {
    width: 1017px;
  }

  .title {
    margin-bottom: 32px;
    text-align: left;
  }

  .main {
    padding: 24px 32px;
    border-radius: 8px;
    background-color: var(--wpp-grey-color-000);

    .section {
      margin-bottom: 32px;
    }

    .subSection {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.withGap {
        gap: 16px;
      }
    }

    .inlineMessageSection {
      width: 226.25px;
    }

    .emptyStatesSubSection {
      display: flex;
      gap: 38px;
    }

    .inputsSubSection {
      display: flex;
      align-items: center;

      > * {
        width: 178px;
        margin-right: 16px;
        margin-bottom: 32px;
      }
    }

    .checkboxSubSection {
      display: flex;
      align-items: center;

      > * {
        flex: 1;
        margin-right: 8px;
        margin-bottom: 16px;
      }
    }

    .pillsSubSection {
      display: flex;
      align-items: center;

      > * {
        margin-right: 8px;
      }
    }

    .cardsSubSection {
      display: flex;
      align-items: center;

      > * {
        flex: 1;
        margin-right: 24px;
      }
    }

    .sectionLabel {
      margin-bottom: 24px;
    }

    .blockLabel {
      margin-bottom: 16px;
      color: var(--wpp-grey-color-700);
    }

    .block {
      width: 46%;
      margin-bottom: 32px;
    }

    .componentsList {
      display: flex;

      > * {
        flex: 1;
      }

      .invertedButtons {
        display: flex;
        align-items: center;
        gap: 17px;
        border-radius: 8px;
        background-color: var(--wpp-grey-color-900);
        padding: 24px;

        .invertedPrimaryButtonHover {
          &::part(button) {
            background-color: rgba(255, 255, 255, 88%);
          }
        }

        .invertedPrimaryButtonActive {
          &::part(button) {
            background-color: rgba(255, 255, 255, 80%);
          }
        }

        .invertedSecondaryButtonHover {
          &::part(button) {
            background-color: rgba(248, 249, 251, 12%);
          }
          // dummy commit to test the pipeline fix
        }

        .invertedSecondaryButtonActive {
          &::part(button) {
            background-color: rgba(248, 249, 251, 18%);
          }
        }
      }
    }

    .card {
      padding: 8px;
      box-shadow: 0 1px 4px rgba(52, 58, 63, 0.05), 0 4px 12px rgba(52, 58, 63, 0.12);
      border-radius: 6px;
    }

    .divider {
      margin-bottom: 32px;
    }

    .listItem {
      --li-width: 100%;
      width: 100%;
      margin-bottom: 4px;
    }

    .primaryButtonHover {
      --button-primary-bg-color: var(--button-primary-bg-color-hover);
    }
    .primaryButtonActive {
      --button-primary-bg-color: var(--button-primary-bg-color-active);
    }
    .secondaryButtonHover {
      --button-secondary-bg-color: var(--button-secondary-bg-color-hover);
    }
    .secondaryButtonActive {
      --button-secondary-bg-color: var(--button-secondary-bg-color-active);
    }

    .primaryActionButtonHover {
      :global(.wpp-action-button) {
        --ab-primary-text-color: var(--ab-primary-text-color-hover);
        --ab-primary-icon-color: var(--ab-primary-icon-color-hover);

        &::part(overlay) {
          background-color: var(--ab-bg-color-hover);
          opacity: var(--ab-bg-opacity-hover);
        }
      }
    }
    .primaryActionButtonActive {
      :global(.wpp-action-button) {
        --ab-primary-text-color: var(--ab-primary-text-color-active);
        --ab-primary-icon-color: var(--ab-primary-icon-color-active);

        &::part(overlay) {
          background-color: var(--ab-bg-color-active);
          opacity: var(--ab-bg-opacity-active);
        }
      }
    }
    .secondaryActionButtonHover {
      :global(.wpp-action-button) {
        --ab-secondary-text-color: var(--ab-secondary-text-color-hover);
        --ab-secondary-icon-color: var(--ab-secondary-icon-color-hover);

        &::part(overlay) {
          background-color: var(--ab-bg-color-hover);
          opacity: var(--ab-bg-opacity-hover);
        }
      }
    }
    .secondaryActionButtonActive {
      :global(.wpp-action-button) {
        --ab-secondary-text-color: var(--ab-secondary-text-color-active);
        --ab-secondary-icon-color: var(--ab-secondary-icon-color-active);

        &::part(overlay) {
          background-color: var(--ab-bg-color-active);
          opacity: var(--ab-bg-opacity-active);
        }
      }
    }

    .filterButtonHover {
      --filter-button-bg-color: var(--filter-button-bg-color-hover);
      --filter-button-text-color: var(--filter-button-text-color-hover);
      --filter-button-counter-color: var(--filter-button-counter-color-hover);
      --filter-button-icon-color: var(--filter-button-icon-color-hover);
    }
    .filterButtonActive {
      --filter-button-bg-color: var(--filter-button-bg-color-active);
      --filter-button-text-color: var(--filter-button-text-color-active);
      --filter-button-counter-color: var(--filter-button-counter-color-active);
      --filter-button-icon-color: var(--filter-button-icon-color-active);
    }

    .iconButtonHover {
      --button-secondary-bg-color: var(--wpp-primary-color-100);
    }
    .iconButtonActive {
      --button-secondary-bg-color: var(--wpp-primary-color-200);
    }

    .floatingButtonHover {
      --fb-primary-bg-color: var(--fb-primary-bg-color-hover);
    }
    .floatingButtonActive {
      --fb-primary-bg-color: var(--fb-primary-bg-color-active);
    }

    .backToTopButtonHover {
      --bttb-border-color: var(--bttb-border-color-hover);
      --bttb-bg-color: var(--bttb-bg-color-hover);
      --bttb-icon-color: var(--bttb-icon-color-hover);
    }
    .backToTopButtonActive {
      --bttb-border-color: var(--bttb-border-color-active);
      --bttb-bg-color: var(--bttb-bg-color-active);
      --bttb-icon-color: var(--bttb-icon-color-active);
    }

    .listItemHover {
      --li-bg-color: var(--li-bg-color-hover);
    }
    .listItemActive {
      --li-bg-color: var(--li-bg-color-active);
    }
    .listItemSelectedHover {
      :global(.wpp-list-item) {
        --li-bg-color: var(--li-bg-color-hover);
        --li-label-text-color-selected: var(--li-label-text-color-selected-hover);

        &::part(checkbox) {
          --checkbox-bg-color-checked: var(--checkbox-bg-color-checked-hover);
          --checkbox-border-color-checked: var(--checkbox-border-color-checked-hover);
        }
      }
    }
    .listItemSelectedActive {
      :global(.wpp-list-item) {
        --li-bg-color: var(--li-bg-color-active);
        --li-label-text-color-selected: var(--li-label-text-color-selected-active);

        &::part(checkbox) {
          --checkbox-bg-color-checked: var(--checkbox-bg-color-checked-active);
          --checkbox-border-color-checked: var(--checkbox-border-color-checked-active);
        }
      }
    }

    .tabHover {
      --tab-text-color: var(--tab-text-color-hover);
    }
    .tabActive {
      --tab-text-color: var(--tab-text-color-active);
    }

    .inputHover {
      --text-input-bg-color: var(--text-input-bg-color-hover);
      --text-input-warning-border-color: var(--text-input-warning-border-color-hover);
      --text-input-error-border-color: var(--text-input-error-border-color-hover);
    }
    .inputActive {
      --text-input-bg-color: var(--text-input-bg-color-active);
      --text-input-warning-border-color: var(--text-input-warning-border-color-active);
      --text-input-error-border-color: var(--text-input-error-border-color-active);
    }

    .checkboxHover {
      --checkbox-bg-color: var(--checkbox-bg-color-hover);
      --checkbox-border-color: var(--checkbox-border-color-hover);
    }
    .checkboxActive {
      --checkbox-bg-color: var(--checkbox-bg-color-active);
      --checkbox-border-color: var(--checkbox-border-color-active);
    }
    .checkboxSelectedHover {
      --checkbox-bg-color: var(--checkbox-bg-color-hover);
      --checkbox-border-color: var(--checkbox-border-color-hover);
      --checkbox-border-color-checked: var(--checkbox-border-color-checked-hover);
    }
    .checkboxSelectedActive {
      --checkbox-bg-color: var(--checkbox-bg-color-active);
      --checkbox-border-color: var(--checkbox-border-color-active);
      --checkbox-border-color-checked: var(--checkbox-border-color-checked-active);
    }

    .pillsHover {
      --pill-bg-color: var(--pill-bg-color-hover);
      --pill-border-color: var(--pill-border-color-hover);
      --pill-checked-multiple-border-color: var(--pill-checked-multiple-border-color-hover);
    }
    .pillsActive {
      --pill-bg-color: var(--pill-bg-color-active);
      --pill-border-color: var(--pill-border-color-active);
    }
    .pillsSelectedHover {
      --pill-bg-color: var(--pill-bg-color-hover);
      --pill-border-color: var(--pill-border-color-hover);
      --pill-checked-multiple-border-color: var(--pill-checked-multiple-border-color-hover);
    }

    .clickableCardHover {
      --card-interactive-box-shadow-color: var(--card-interactive-box-shadow-color-hover);
    }
    .clickableCardActive {
      --card-interactive-box-shadow-color: var(--card-interactive-box-shadow-color-active);
    }

    .selectableCardHover {
      --card-choosable-border-color: var(--card-choosable-border-color-hover);
      --card-choosable-selected-border-color: var(--card-choosable-selected-border-color-hover);
    }
    .selectableCardActive {
      --card-choosable-border-color: var(--card-choosable-border-color-active);
      --card-choosable-selected-border-color: var(--card-choosable-selected-border-color-active);
    }
    .selectableCardSelectedHover {
      --card-choosable-border-color: var(--card-choosable-border-color-hover);
      --card-choosable-selected-border-color: var(--card-choosable-selected-border-color-hover);
    }
    .selectableCardSelectedActive {
      --card-choosable-border-color: var(--card-choosable-border-color-active);
      --card-choosable-selected-border-color: var(--card-choosable-selected-border-color-active);
    }
  }
}
